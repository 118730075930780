import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { LOAD_FEATURED_CATALOGS_FAIL, LOAD_FEATURED_CATALOGS_REQUEST, LOAD_FEATURED_CATALOGS_SUCCESS } from './actions';
import api from '@/redux/api/featuredCatalogs';
import shuffle from 'lodash/shuffle';

// reducer
export type FeaturedCatalogsSlice = {
    error: boolean;
    errorMessage: string;
    featuredCatalogs: any[];
    submitted: boolean;
    success: boolean;
};

export const defaultFeaturedCatalogsSlice: FeaturedCatalogsSlice = {
    error: false,
    errorMessage: '',
    featuredCatalogs: [],
    submitted: false,
    success: false,
};

export default function reducer(
    state: FeaturedCatalogsSlice = defaultFeaturedCatalogsSlice,
    action: any = {}
): FeaturedCatalogsSlice {
    switch (action.type) {
        case LOAD_FEATURED_CATALOGS_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };
        case LOAD_FEATURED_CATALOGS_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: '',
                submitted: true,
                success: false,
            };
        case LOAD_FEATURED_CATALOGS_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: '',
                featuredCatalogs: shuffle(action.payload),
                submitted: false,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const featuredCatalogsSlice = createSelector(stateSelector, (state) => state.featuredCatalogs);

export const getFeaturedCatalogs = createSelector(featuredCatalogsSlice, (state) => state.featuredCatalogs);

/* ACTION CREATORS */
export const fetchFeaturedCatalogs = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            type: LOAD_FEATURED_CATALOGS_REQUEST,
        });
        const response = await api.fetchFeaturedCatalogs({
            deployment,
        });
        return dispatch({
            meta: {
                actionTime: Date.now(),
            },
            payload: response.data,
            type: LOAD_FEATURED_CATALOGS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_FEATURED_CATALOGS_FAIL,
        });
    }
};
