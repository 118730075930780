import { createSlice } from '@reduxjs/toolkit';
import { defaulFavoritesSlice } from './favorites.types';
import {
    loadCatalogFavorites,
    submitFavoriteItemForCatalog,
    submitUnfavoriteItemForCatalog,
} from './favorites.actions';

const favoriteItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCatalogFavorites.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
        });
        builder.addCase(loadCatalogFavorites.fulfilled, (slice, { payload }) => {
            slice.itemIds = payload;
            slice.loading = false;
            slice.error = false;
        });
        builder.addCase(loadCatalogFavorites.rejected, (slice) => {
            slice.loading = false;
            slice.error = true;
        });

        builder.addCase(submitFavoriteItemForCatalog.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
        });
        builder.addCase(submitFavoriteItemForCatalog.fulfilled, (slice) => {
            slice.toggleFavoriteSuccess = true;
            slice.loading = false;
            slice.error = false;
        });
        builder.addCase(submitFavoriteItemForCatalog.rejected, (slice) => {
            slice.loading = false;
            slice.error = true;
        });

        builder.addCase(submitUnfavoriteItemForCatalog.pending, (slice) => {
            slice.loading = true;
            slice.error = false;
        });
        builder.addCase(submitUnfavoriteItemForCatalog.fulfilled, (slice) => {
            slice.toggleFavoriteSuccess = true;
            slice.loading = false;
            slice.error = false;
        });
        builder.addCase(submitUnfavoriteItemForCatalog.rejected, (slice) => {
            slice.loading = false;
            slice.error = true;
        });
    },
    initialState: defaulFavoritesSlice,
    name: 'favoriteItems',
    reducers: {},
});

export const { reducer: favoriteItemsReducer } = favoriteItemsSlice;
