import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { LOAD_ABOUT_DATA_SUCCESS } from './actions';
import { LOAD_HOME_DATA_SUCCESS } from '@/redux/modules/home/home.actions';

// reducer
export type FeaturedCategoriesSlice = {
    categories: any[];
    featuredCategoriesTitle: string;
};

export const defaultFeaturedCategoriesSlice: FeaturedCategoriesSlice = {
    categories: [],
    featuredCategoriesTitle: '',
};

export default function reducer(
    state: FeaturedCategoriesSlice = defaultFeaturedCategoriesSlice,
    action: any = {}
): FeaturedCategoriesSlice {
    let categories;
    let featuredCategoriesTitle;

    switch (action.type) {
        case LOAD_ABOUT_DATA_SUCCESS:
        case LOAD_HOME_DATA_SUCCESS:
            // categories
            if (action.payload.categories) {
                categories = action.payload.categories.map((x) => {
                    // in the case that we're passed the full url, remove the part we dont need.
                    let link = x.link || '';
                    link = link.replace(/https?:\/\/www.liveauctioneers.com/g, '');
                    // add in the label until the api returns it
                    return {
                        label: x.title,
                        ...x,
                        link,
                    };
                });
            } else {
                categories = [];
            }

            if (action.payload.featuredCategoriesTitle) {
                featuredCategoriesTitle = action.payload.featuredCategoriesTitle;
            }

            return {
                ...state,
                categories,
                featuredCategoriesTitle,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const featuredCategoriesSlice = createSelector(stateSelector, (state) => state.featuredCategories);

export const featuredCategoriesSelector = createSelector(featuredCategoriesSlice, (state) => state.categories);

export const featuredCategoriesTitleSelector = createSelector(
    featuredCategoriesSlice,
    (state) => state.featuredCategoriesTitle
);
