import { AppDispatch, AppGetState } from '@/redux/store';
import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchCatalogFavorites, postFavoriteItem, postUnfavoriteItem } from './favorites.api';
import { FetchCatalogFavoritesResponse, PostFavoriteItemResponse, PostUnfavoriteItemResponse } from './favorites.types';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { isItemFavorited } from './favorites.selectors';

export const loadCatalogFavorites = createAsyncThunk<
    FetchCatalogFavoritesResponse['payload'],
    number,
    AsyncThunkConfigWithRejectValue
>('la/domain/favorites/loadCatalogFavorites', async (catalogId: number, { getState, rejectWithValue }) => {
    try {
        const state = getState();

        const isLoggedIn = isUserLoggedIn(state);

        if (!isLoggedIn) {
            return [];
        }

        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);

        const { payload } = await fetchCatalogFavorites({
            authToken,
            bidderId,
            catalogId,
            deployment,
        });
        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching favorite items'));
    }
});

export const toggleFavoriteItemForCatalog =
    (itemId: number, catalogId: number) => (dispatch: AppDispatch, getState: AppGetState) => {
        if (isItemFavorited(getState(), itemId)) {
            return dispatch(submitUnfavoriteItemForCatalog({ catalogId, itemId }));
        } else {
            return dispatch(submitFavoriteItemForCatalog({ catalogId, itemId }));
        }
    };

export const submitFavoriteItemForCatalog = createAsyncThunk<
    PostFavoriteItemResponse['error'],
    { catalogId: number; itemId: number },
    AsyncThunkConfigWithRejectValue
>(
    'la/domain/favorites/submitFavoriteItemForCatalog',
    async ({ catalogId, itemId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const bidderId = getBidderId(state);
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            await postFavoriteItem({
                authToken,
                bidderId,
                catalogId,
                deployment,
                itemId,
            });
            await dispatch(loadCatalogFavorites(catalogId));
        } catch (error) {
            return rejectWithValue(new Error('Error in favoriting item'));
        }
    }
);

export const submitUnfavoriteItemForCatalog = createAsyncThunk<
    PostUnfavoriteItemResponse['error'],
    { catalogId: number; itemId: number },
    AsyncThunkConfigWithRejectValue
>(
    'la/domain/favorites/submitUnfavoriteItemForCatalog',
    async ({ catalogId, itemId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const bidderId = getBidderId(state);
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            await postUnfavoriteItem({
                authToken,
                bidderId,
                deployment,
                itemId,
            });

            await dispatch(loadCatalogFavorites(catalogId));
        } catch (error) {
            return rejectWithValue(new Error('Error in unfavoriting item'));
        }
    }
);
