import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchSearchResults } from '@/redux/modules/search/search.api';
import { getAugmentedSearchQuery } from '@/redux/modules/search/search.helpers';
import { getAuthToken, getZipCodeGeoCoordinates } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { getUseGeoLocation } from '@/redux/modules/search/search.selectors';
import { getUserExcludedFacets } from './search/exclusions/searchExclusions.selectors';
import {
    LOAD_DISCOVER_ITEMS_FAIL,
    LOAD_DISCOVER_ITEMS_REQUEST,
    LOAD_DISCOVER_ITEMS_SUCCESS,
    LOAD_SHOULD_DISCOVER_SUCCESS,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import discoverTopicApi from '../api/discover';
import isEqual from 'lodash/isEqual';
import ms from 'ms';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type DiscoverSlice = {
    itemIds: any[];
    loaded: number;
    loading: boolean;
    searchQuery: {};
    sortOptions: any[];
    topics: {};
    totalPages: number;
    totalRecords: number;
};

export const defaultDiscoverSlice: DiscoverSlice = {
    itemIds: [],
    loaded: 0,
    loading: false,
    searchQuery: {},
    sortOptions: [],
    topics: {},
    totalPages: 0,
    totalRecords: 0,
};

export default function reducer(state: DiscoverSlice = defaultDiscoverSlice, action: any = {}): DiscoverSlice {
    switch (action.type) {
        case LOAD_DISCOVER_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_DISCOVER_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_DISCOVER_ITEMS_SUCCESS:
            return {
                ...state,
                itemIds: cloneDeep(action.payload.itemIDs),
                loaded: action.meta.actionTime,
                loading: false,
                searchQuery: action.meta.searchQuery,
                sortOptions: action.payload.sort,
                totalPages: action.payload.totalPages,
                totalRecords: action.payload.totalFound,
            };
        case LOAD_SHOULD_DISCOVER_SUCCESS:
            const topics = {
                ...state.topics,
                [action.meta.topic]: action.payload,
            };
            return {
                ...state,
                topics,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const discoverSelector = createSelector(stateSelector, (state) => state.discover);

const idSelector = (_: GlobalState, id: string) => id;

const topicsSelector = createSelector(discoverSelector, (state) => state.topics);

const loadedSelector = createSelector(discoverSelector, (state) => state.loaded);

const loadingSelector = createSelector(discoverSelector, (state) => state.loading);

export const getShouldDiscoverTopic = createSelector([topicsSelector, idSelector], (topics, id) => Boolean(topics[id]));

export const getDiscoverPaginationFilter = createSelector(discoverSelector, (state) => ({
    totalPages: state.totalPages,
    totalRecords: state.totalRecords,
}));

export const getDiscoverPaginationRecordsCount = createSelector(
    getDiscoverPaginationFilter,
    ({ totalRecords }) => totalRecords
);

export const getDiscoverItemIds = createSelector(discoverSelector, (state) => state.itemIds || []);

const getLoadTimeForDiscoverItemIds = createSelector([loadedSelector], (loaded) => loaded || 0);

const isDiscoverItemIdsLoading = createSelector([loadingSelector], (loading) => loading);

const shouldFetchDiscoverItemIds = (state: GlobalState, searchQuery: any) => {
    const data = discoverSelector(state);
    if (data && data.totalPages) {
        if (!isEqual(data.searchQuery, searchQuery)) {
            return true;
        }
        const loaded = getLoadTimeForDiscoverItemIds(state);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isDiscoverItemIdsLoading(state);
    return !loading;
};

/* ACTION CREATORS */

const loadDiscoverItemIds = (searchQuery: any) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);

        dispatch({
            type: LOAD_DISCOVER_ITEMS_REQUEST,
        });

        // Annotate facets which have user-defined search exclusions
        const searchExclusions = getUserExcludedFacets(state);
        const augmentedSearchQuery = getAugmentedSearchQuery(
            { ...(isBot ? { isBot } : {}), ...searchQuery },
            searchExclusions
        );

        const useGeoLocation = getUseGeoLocation(state);
        const geoCoordinates = getZipCodeGeoCoordinates(state);

        const results = await fetchSearchResults({
            authToken,
            deployment,
            geoCoordinates,
            ipAddress,
            searchQuery: augmentedSearchQuery,
            useGeoLocation,
        });

        return dispatch({
            meta: { actionTime: Date.now(), searchQuery },
            payload: results.payload,
            type: LOAD_DISCOVER_ITEMS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_DISCOVER_ITEMS_FAIL,
        });
    }
};

const fetchShouldDiscoverTopic = (topic: string) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const results = await discoverTopicApi.fetchShouldDiscoverTopic({
            authToken,
            deployment,
            topic,
        });

        return dispatch({
            meta: { actionTime: Date.now(), topic },
            payload: results.payload,
            type: LOAD_SHOULD_DISCOVER_SUCCESS,
        });
    } catch (e) {
        console.log('Error loading discover topic', e);
    }
};

export const fetchShouldDiscoverTopicIfNeeded =
    (topic: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        if (!getShouldDiscoverTopic(state, topic)) {
            return dispatch(fetchShouldDiscoverTopic(topic));
        }
        return Promise.resolve();
    };

export const fetchDiscoverItemIdsIfNeeded = (searchQuery) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    if (shouldFetchDiscoverItemIds(state, searchQuery)) {
        return dispatch(loadDiscoverItemIds(searchQuery));
    }
    return Promise.resolve();
};
