import { ApiHosts, handleResponseWithNon200Errors, makeDelete, makeGet, makePost } from '../../api/helpers';
import { FetchCatalogFavoritesResponse, PostFavoriteItemResponse, PostUnfavoriteItemResponse } from './favorites.types';

/**
 * @category Favorites API
 * @see fetchCatalogFavorites
 */
type FetchCatalogFavoritesParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
};

/**
 * GET request to Favorites API service sends a catalogId and returns a list of item id's
 * @function fetchCatalogFavorites
 * @category Favorites API
 * @param {FetchCatalogFavoritesParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditems
 * @see https://github.com/LIVEauctioneers/saveditem-crows
 */
export const fetchCatalogFavorites = ({ authToken, bidderId, catalogId, deployment }: FetchCatalogFavoritesParams) =>
    new Promise<FetchCatalogFavoritesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'saveditems',
            authToken,
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({
            bidderId,
            catalogId,
        });
        request.end((err: string, response: object) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Favorites API
 * @see postFavoriteItem
 */
type PostFavoriteItemParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    itemId: number;
};

/**
 * POST request to Favorites API which saves item to favorites
 * @function postFavoriteItem
 * @category Favorites API
 * @param {PostFavoriteItemParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditems
 * @see https://github.com/LIVEauctioneers/saveditem-crows
 */

export const postFavoriteItem = ({ authToken, bidderId, catalogId, deployment, itemId }: PostFavoriteItemParams) =>
    new Promise<PostFavoriteItemResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'saveditems',
            authToken,
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({
            bidderId,
        });
        request.send({
            catalogId,
            lotId: itemId,
        });
        request.end((err: string, response: object) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Favorites API
 * @see postUnfavoriteItem
 */
type PostUnfavoriteItemParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    itemId: number;
};

/**
 * DELETE request to Favorites API which removes item from favorites
 * @function postUnfavoriteItem
 * @category Favorites API
 * @param {PostUnfavoriteItemParams}
 * @see https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/saveditems
 * @see https://github.com/LIVEauctioneers/saveditem-crows
 */

export const postUnfavoriteItem = ({ authToken, bidderId, deployment, itemId }: PostUnfavoriteItemParams) =>
    new Promise<PostUnfavoriteItemResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: 'saveditems',
            authToken,
            deployment,
            path: ApiHosts.Crows,
        });
        request.query({
            bidderId,
            lotId: itemId,
        });
        request.end((err: string, response: object) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
