import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import api from '@/redux/api/currencyConversions';
import ms from 'ms';
import type { CurrencyConversion } from '@/types/Currency';

/* Action Types */
const LOAD_CURRENCY_CONVERSION_FAIL = 'la/ui/currency/LOAD_FAIL';
const LOAD_CURRENCY_CONVERSION_REQUEST = 'la/ui/currency/LOAD_REQUEST';
const LOAD_CURRENCY_CONVERSION_SUCCESS = 'la/ui/currency/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

interface CurrencySlice {
    readonly conversions: CurrencyConversion[];
    readonly loaded: number;
    readonly loading: boolean;
}

// reducer
export const defaultCurrencySlice: CurrencySlice = {
    conversions: [],
    loaded: 0,
    loading: false,
};

export default function reducer(state = defaultCurrencySlice, action: any = {}): CurrencySlice {
    switch (action.type) {
        case LOAD_CURRENCY_CONVERSION_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_CURRENCY_CONVERSION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_CURRENCY_CONVERSION_SUCCESS:
            return {
                ...state,
                conversions: action.payload.conversions,
                loaded: action.meta.actionTime,
                loading: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const currencySelector = createSelector(stateSelector, (state) => state.currency);

const passThroughSelector = (state: GlobalState, id: string) => id;

export const getCurrencyConversions = createSelector(currencySelector, (state) => state.conversions || []);

export const getCurrencyConversion = createSelector(
    getCurrencyConversions,
    passThroughSelector,
    (conversions, currencyCode) => (currencyCode ? conversions.find((c) => c.currencyCode === currencyCode) : undefined)
);

const getLoadTimeForCurrencyConversions = createSelector(currencySelector, (currency) => currency.loaded || 0);
const isCurrencyConversionsLoading = createSelector(currencySelector, (currency) => currency.loading || false);

const shouldFetchCurrencyConversions = (state) => {
    const loaded = getLoadTimeForCurrencyConversions(state);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }
    return !isCurrencyConversionsLoading(state);
};

/* ACTION CREATORS */
const loadCurrencyConversions = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            type: LOAD_CURRENCY_CONVERSION_REQUEST,
        });
        const response = await api.fetchCurrencyConversions({ deployment });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_CURRENCY_CONVERSION_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_CURRENCY_CONVERSION_FAIL,
        });
    }
};

export const fetchCurrencyConversionsIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    if (shouldFetchCurrencyConversions(getState())) {
        return dispatch(loadCurrencyConversions());
    }
    return Promise.resolve();
};
