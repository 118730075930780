import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const idSelector = (_: GlobalState, id: number) => id;

const getSlice = ({ favoriteItems }: GlobalState) => favoriteItems;
export const getAllFavoriteItems = createSelector(getSlice, ({ itemIds }) => itemIds);

export const isItemFavorited = createSelector([getAllFavoriteItems, idSelector], (itemIds, itemId) =>
    itemIds.includes(itemId)
);
