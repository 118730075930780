export type FavoritesState = {
    error: boolean;
    itemIds: number[];
    loaded: { [id: number]: number };
    loading: boolean;
    toggleFavoriteSuccess: boolean;
};

export const defaulFavoritesSlice: FavoritesState = {
    error: false,
    itemIds: [],
    loaded: {},
    loading: false,
    toggleFavoriteSuccess: false,
};

export type FetchCatalogFavoritesResponse = {
    error: boolean;
    payload: number[];
};

export type PostFavoriteItemResponse = {
    error: boolean;
};

export type PostUnfavoriteItemResponse = {
    error: boolean;
};
