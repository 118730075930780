import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getBaseUrl, getDeployment } from '@/redux/modules/config';
import { MessageType } from '@/components/Modals/GetHelp/enums/GetHelpModal.enum';
import { postSubmitZendeskFeedback, postSubmitZendeskGetHelp } from '@/redux/api/feedback';
import { trackSendSupportMessage } from './analytics';

/* Action Types */
const RESET_FEEDBACK_MODAL = 'la/ui/submitFeedback/RESET';
const SUBMIT_FEEDBACK_FAIL = 'la/ui/submitFeedback/FAIL';
const SUBMIT_FEEDBACK_REQUEST = 'la/ui/submitFeedback/REQUEST';
const SUBMIT_FEEDBACK_SUCCESS = 'la/ui/submitFeedback/SUCCESS';

// reducer
export type FeedbackSlice = {
    submitted: boolean;
    success: boolean;
};

export const defaultFeedbackSlice: FeedbackSlice = {
    submitted: false,
    success: false,
};

export default function reducer(state: FeedbackSlice = defaultFeedbackSlice, action: any = {}): FeedbackSlice {
    switch (action.type) {
        case RESET_FEEDBACK_MODAL:
            return {
                ...state,
                submitted: false,
                success: false,
            };
        case SUBMIT_FEEDBACK_FAIL:
            return {
                ...state,
                submitted: false,
                success: false,
            };
        case SUBMIT_FEEDBACK_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SUBMIT_FEEDBACK_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const feedbackSlice = createSelector(stateSelector, (state) => state.feedback);

export const getSubmitted = createSelector(feedbackSlice, (state) => state.submitted);

export const getSuccess = createSelector(feedbackSlice, (state) => state.success);

/* ACTION CREATORS */
export const resetModal = () => ({
    type: RESET_FEEDBACK_MODAL,
});

export enum FeedbackTypes {
    GetHelp = 'get help',
    LiveBidding = 'live bidding feedback',
    Site = 'Site feedback',
}

type BaseSubmitFeedbackParams = {
    email: string;
    message: string;
    page: string;
};

type SubmitFeedbackParams = BaseSubmitFeedbackParams & {
    isBiddingConsole: boolean;
};

export const submitFeedback =
    ({ email, isBiddingConsole, message, page }: SubmitFeedbackParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const baseUrl = getBaseUrl(state);

            const source = isBiddingConsole ? FeedbackTypes.LiveBidding : FeedbackTypes.Site;

            const feedbackObject = {
                email,
                message,
                page: `${baseUrl}${page}`,
                source,
            };

            dispatch({
                payload: {
                    feedback: feedbackObject,
                },
                type: SUBMIT_FEEDBACK_REQUEST,
            });

            await postSubmitZendeskFeedback({
                authToken,
                deployment,
                feedbackObject,
            });

            dispatch({
                payload: {
                    feedback: feedbackObject,
                },
                type: SUBMIT_FEEDBACK_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_FEEDBACK_FAIL,
            });
        }
    };

type SubmitGetHelpMessageParams = BaseSubmitFeedbackParams & {
    catalogId: number;
    itemId: number;
    messageType: MessageType;
    subject: string;
};

export const submitGetHelpMessage =
    ({ catalogId, itemId, message, messageType, page, subject }: SubmitGetHelpMessageParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const baseUrl = getBaseUrl(state);
            const source = FeedbackTypes.GetHelp;

            const feedbackObject = {
                message,
                messageType,
                page: `${baseUrl}${page}`,
                source,
                subject,
            };

            dispatch({
                payload: {
                    feedback: feedbackObject,
                },
                type: SUBMIT_FEEDBACK_REQUEST,
            });

            await postSubmitZendeskGetHelp({
                authToken,
                deployment,
                feedbackObject,
            });

            dispatch({
                payload: {
                    feedback: feedbackObject,
                },
                type: SUBMIT_FEEDBACK_SUCCESS,
            });

            dispatch(trackSendSupportMessage({ catalogId, itemId }));
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_FEEDBACK_FAIL,
            });
        }
    };
