import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import api from '@/redux/api/curatedCollection';

const LOAD_CURATED_COLLECTION_FAIL = 'LOAD_CURATED_COLLECTION_FAIL';
const LOAD_CURATED_COLLECTION_REQUEST = 'LOAD_CURATED_COLLECTION_REQUEST';
const LOAD_CURATED_COLLECTION_SUCCESS = 'LOAD_CURATED_COLLECTION_SUCCESS';

/* reducer */
export type CuratedCollectionSlice = {
    URLName: string;
    error: boolean;
    isLoading: boolean;
    itemIds: any[];
    label: string;
    submitted: boolean;
    success: boolean;
    totalFound: number;
};

export const defaultCuratedCollectionSlice: CuratedCollectionSlice = {
    error: false,
    isLoading: true,
    itemIds: [],
    label: '',
    submitted: false,
    success: false,
    totalFound: 0,
    URLName: '',
};

export default function reducer(
    state: CuratedCollectionSlice = defaultCuratedCollectionSlice,
    action: any = {}
): CuratedCollectionSlice {
    switch (action.type) {
        case LOAD_CURATED_COLLECTION_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };
        case LOAD_CURATED_COLLECTION_SUCCESS:
            return {
                ...state,
                itemIds: action.payload.lots,
                label: action.payload.label,
                submitted: false,
                success: true,
                totalFound: action.payload.lots ? action.payload.lots.length : 0,
                URLName: action.payload.name,
            };
        case LOAD_CURATED_COLLECTION_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const curatedCollectionSelector = createSelector(stateSelector, (state) => state.curatedCollection);

export const getCuratedCollectionItemIds = createSelector(curatedCollectionSelector, ({ itemIds }) => itemIds || []);

export const getCuratedCollectionTotalFound = createSelector(
    curatedCollectionSelector,
    ({ totalFound }) => totalFound || 0
);

export const getCuratedCollectionLabel = createSelector(
    curatedCollectionSelector,
    ({ label }) => label || 'Example Label'
);

/* ACTION CREATORS */
export const fetchCuratedCollectionData =
    (collectionID: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            dispatch({
                type: LOAD_CURATED_COLLECTION_REQUEST,
            });

            // Annotate facets which have user-defined search exclusions
            const results = await api.fetchCuratedCollectionItemIDs({
                collectionID,
                deployment,
            });
            dispatch({
                payload: results.payload,
                type: LOAD_CURATED_COLLECTION_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { collectionID },
                payload: error,
                type: LOAD_CURATED_COLLECTION_FAIL,
            });
        }
    };
