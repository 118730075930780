export const DEFAULT_STATE = [];

// logs server-side redux errors
export default function reducer(state: any = DEFAULT_STATE, action: any = {}) {
    const actionType = action?.type?.toLowerCase();
    if (!Boolean(actionType)) {
        return state;
    }
    // "fail" is our old redux actions, with hard-coded strings
    // "rejected" is redux toolkit
    if (actionType.includes('fail') || actionType.includes('rejected')) {
        const timestamp = Date.now();
        const error = {
            ...action,
            timestamp,
        };

        return [...state, error];
    }

    return state;
}
