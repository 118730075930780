import * as api from '@/redux/api/emailList';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchSmsAlertsIfNeeded } from './smsAlerts';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import ms from 'ms';

/* Action Types */
export const LOAD_EMAIL_LIST_FAIL = 'la/user/emailList/LOAD_FAIL';
export const LOAD_EMAIL_LIST_REQUEST = 'la/user/emailList/LOAD_REQUEST';
export const LOAD_EMAIL_LIST_SUCCESS = 'la/user/emailList/LOAD_SUCCESS';

export const SUBSCRIBE_FAIL = 'la/user/emailList/SUBSCRIBE_FAIL';
export const SUBSCRIBE_REQUEST = 'la/user/emailList/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'la/user/emailList/SUBSCRIBE_SUCCESS';

export const UNSUBSCRIBE_FAIL = 'la/user/emailList/UNSUBSCRIBE_FAIL';
export const UNSUBSCRIBE_REQUEST = 'la/user/emailList/UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'la/user/emailList/UNSUBSCRIBE_SUCCESS';

const REDUX_STORE_TIME = ms('5m');

// reducer
export type EmailListSlice = {
    lists: any[];
    loaded: number;
    loading: boolean;
};

export const defaultEmailListSlice: EmailListSlice = {
    lists: [],
    loaded: 0,
    loading: false,
};

export default function reducer(state: EmailListSlice = defaultEmailListSlice, action: any = {}): EmailListSlice {
    let lists;

    switch (action.type) {
        case LOAD_EMAIL_LIST_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_EMAIL_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_EMAIL_LIST_SUCCESS:
            return {
                ...state,
                lists: action.payload,
                loaded: action.meta.actionTime,
                loading: false,
            };
        case SUBSCRIBE_REQUEST:
            lists = [...state.lists];

            if (action.payload === 'Subscribe All') {
                lists.forEach((x) => {
                    if (x.type === 'newsletter') {
                        x.subscribed = true;
                    }
                });
            } else {
                lists.forEach((x) => {
                    if (x.code === action.payload) {
                        x.subscribed = true;
                    }
                });
            }

            return {
                ...state,
                lists,
            };
        case UNSUBSCRIBE_REQUEST:
            lists = [...state.lists];

            if (action.payload === 'Unsubscribed All') {
                lists.forEach((x) => {
                    if (x.type === 'newsletter') {
                        x.subscribed = false;
                    }
                });
            } else {
                lists.forEach((x) => {
                    if (x.code === action.payload) {
                        x.subscribed = false;
                    }
                });
            }

            return {
                ...state,
                lists,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const codeSelector = (state: GlobalState, code: string) => code;
export const emailListSelector = createSelector(stateSelector, (state) => state.emailList);

export const getEmailLists = createSelector(
    emailListSelector,
    (state) =>
        (state.lists && state.lists.sort((a, b) => a.type - b.type).sort((a, b) => a.sequence - b.sequence)) || []
);

export const getEmailList = createSelector(
    [emailListSelector, codeSelector],
    (state, code) => state.lists.find((x) => x.code === code) || {}
);

export const getNewsletterList = createSelector(getEmailLists, (lists) => {
    return lists.filter((x) => x.type === 'newsletter');
});

const getLoadTime = createSelector(emailListSelector, (state) => state.loaded || 0);

const isLoading = createSelector(emailListSelector, (state) => state.loading || false);

const shouldFetchEmailLists = (state) => {
    const loaded = getLoadTime(state);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }
    return !isLoading(state);
};

/* ACTION CREATORS */
export const fetchEmailListsIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    if (shouldFetchEmailLists(state)) {
        const deployment = getDeployment(state);
        try {
            dispatch({
                meta: { actionTime: Date.now() },
                type: LOAD_EMAIL_LIST_REQUEST,
            });
            const authToken = getAuthToken(state);
            const response = await api.fetchEmailLists({
                authToken,
                deployment,
            });
            dispatch({
                meta: { actionTime: Date.now() },
                payload: response.data,
                type: LOAD_EMAIL_LIST_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: LOAD_EMAIL_LIST_FAIL,
            });
        }
    } else {
        return Promise.resolve();
    }
};

export const toggleEmailSubscription =
    (code: string, force?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const list = getEmailList(state, code);
        if (list.subscribed || code === 'Unsubscribed All' || force) {
            dispatch({
                payload: code,
                type: UNSUBSCRIBE_REQUEST,
            });
            try {
                const response = await api.postUnsubscribeFromEmailList({
                    authToken,
                    code,
                    deployment,
                });
                dispatch({
                    meta: { code },
                    payload: response.data,
                    type: UNSUBSCRIBE_SUCCESS,
                });
                if (force) {
                    dispatch(fetchSmsAlertsIfNeeded(authToken, true));
                }
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { code },
                    payload: error,
                    type: UNSUBSCRIBE_FAIL,
                });
            }
        } else {
            dispatch({
                payload: code,
                type: SUBSCRIBE_REQUEST,
            });
            try {
                const response = await api.postSubscribeToEmailList({
                    authToken,
                    code,
                    deployment,
                });
                dispatch({
                    meta: { code },
                    payload: response.data,
                    type: SUBSCRIBE_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { code },
                    payload: error,
                    type: SUBSCRIBE_FAIL,
                });
            }
        }
    };
